.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .page-header {
    background-color: var(--dark);
    color: var(--light-light);
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    letter-spacing: 1px;
    font-family: sans-serif;

    &-logo {
      height: 50px;
      margin: 10px;
    }
  }

  .page-body {
    background-color: var(--light);
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

