:root {
  --light-light: #ffffff;
  --light: #f3f3f3;
  --dark-light: #6e757c;
  --dark: #2e4050;
  --dark-dark: #353a40;

  --shadow: rgba(0, 0, 0, 0.25);
  --shadow-dark: rgba(0, 0, 0, 0.5);
}
