@import "./variables/colors.scss";
@import "./variables/assets.scss";
@import "./variables/fonts.scss";

* {
  font-family: "Comic Sans MS", "Tahoma", "Verdana";
  font-family: "Roboto", "sans-serif", "system-ui";
  color: var(--dark-dark);
  user-select: none;
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}