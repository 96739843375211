@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Black.ttf') format('truetype');
  font-weight: bolder;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}