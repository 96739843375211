@import "./variables/media.scss";

.photo-grid {
  flex: 1;
  border: 4px solid var(--dark-dark);
  column-count: 3;
  column-gap: 0px;
  line-height: 0;
  
  &:hover .photo-image {
    &:not(.mod-loading):not(.mod-error) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--shadow-dark);
      }

      &:hover::after {
        content: unset;
      }
    }
  }

  &.mod-empty {
    line-height: initial;
    padding: 20px 30px;
    column-count: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: $smallScreen) {
  .photo-grid {
    column-count: 2;
  }
}

@media (min-width: $largeScreen) and (max-width: $extraLargeScreen) {
  .photo-grid {
    column-count: 4;
  }
}

@media (min-width: $extraLargeScreen) {
  .photo-grid {
    column-count: 5;
  }
}